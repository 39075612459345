/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

html, body { height: 100vh; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; width: 100%;}

.container{
	width:  100%;
	margin:auto;
	display: block;
	max-width: 1440px;
}
.content {
	width:  100%;
	margin:auto;
	max-width: 1024px;
	height: 80vh;
	box-shadow: none!important;
    border: none;
	display: flex!important;
    flex-direction: column;
    justify-content: center;
}

.text-head{padding: 80px 0}
.text-head h1{font-size:35px;text-align: center;}
